
.inner {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
.soundIconContainer {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.soundIcon {
    cursor: pointer;
}

.red {
    color: red;
    text-align: left;
    margin-bottom: 10px;
    display: flex;
}

.green {
    color: green;
    text-align: left;
    margin-bottom: 10px;
    display: flex;
}

.word {
    width: 80%;
}

.btnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 20px;
    margin-top: 20px;
}

@media screen and (max-width :1200px){
    .btnContainer {
        flex-direction: column;
        align-content: space-around;
        height: 135px;
    }
}