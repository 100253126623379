.dropdown {
    height: 30px;
    font-family: 'Lato', sans-serif;
    font-size:18px;
}

.checkboxLabel {
    cursor: pointer;
    font-weight: 550;
    margin-top: 12px;
    margin-left: 10px;
    /* display: inline-block;
    vertical-align: middle; */
    /* margin-right: 10px; Ajustez selon vos besoins */
}

.checkboxInput {
    cursor: pointer;
    width: 20px; /* Ajustez la largeur selon vos besoins */
    height: 20px; /* Ajustez la hauteur selon vos besoins */
    vertical-align: middle;
    margin-right: 10px;
    margin-top: 10px;
}
