.selected {
    background-color: #cf453c;
    border: 2px solid rgb(134, 35, 28);
}

.closeContainer {
    text-align: right;
    margin-top: -12px;
}

.close {
    font-weight: 500;
    cursor: pointer;
    font-size: 30px;
    padding:15px;
}

.close:hover {
    color:#cf453c;
}