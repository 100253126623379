.innerContainer {
    display: flex;
    justify-content: center;
}
.inner {
    width: 60%;
    text-align: left;
}

.pink {
    color: #F27066;
}

.link {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 700;
    font-size: 11px;
}

.link:hover {
    color: #F27066;
}

.small {
    font-size: 11px;
    margin-top: -10px;
}

.normal {
    font-size:14px;
    margin-top: -8px;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    width: 90%;
    height: 300px;
}
@media screen and (max-width:900px) {
    .inner {
        width: 85%;
    }
}