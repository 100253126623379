.container {
    background-color: #F7F7F9;
    width: 70%;
    padding: 30px;
}

.chat {
    display: flex;
    flex-direction: column;
}

.assistant {
    color:#28356B;
    display: flex;
    justify-content: flex-start;
    margin: 5px;
}

.assistantBox { 
    max-width: 80%;
    background-color: #f0f2fa;
    border: 1px solid #28356B;
    border-radius: 5px;
    padding: 5px;
}

.user {
    color:#28356B;
    display: flex;
    align-items: flex-end;
    margin: 5px;
    flex-direction: column;
}

.userBox { 
    max-width: 80%;
    background-color: #ffffff;
    border: 1px solid #28356B;
    border-radius: 5px;
    padding: 5px;
}

.correctionBox {
    max-width: 80%;
    background-color: #fef4f3;
    border: 1px solid #F27066;
    border-radius: 5px;
    padding: 5px;
    margin-top:5px;
}

/* .person {
    font-weight: 700;
    margin-bottom: -10px;
} */

.icon {
    margin-right: 5px;
}

.writeContainer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.sendIcon {
    cursor: pointer;
    color : #28356B;
}

.largeInput {
    padding: 10px; /* Ajoutez un peu de marge intérieure pour un meilleur aspect */
    width: 80%; /* Définissez la largeur selon vos besoins */
    /* 70 */
    height: 20px;
  } 

  .soundContainer {
    padding: 2px 15px;
    color: #28356B;
    cursor:pointer;
}

.soundContainer:hover {
    color: #F27066;
}

.pdfbtn {
    padding: 5px;
    cursor: pointer;
    width: 100px;
}
.pdfBtnContainer {
    margin-top:10px;
    text-align: right;
}
  @media screen and (max-width: 600px) {
    .container {
        width: 92%;
        padding: 12px;
    }
  }