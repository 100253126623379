
.formContainer {
    background-color: rgba(226, 224, 222, 0.847);
    padding: 10px;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
    box-shadow: 3px 3xp grey;
}

.caution {
    max-width: 80%;
    margin-top: 20px;
    background-color: white;
    color:#AF2B26;
    padding: 20px;
    font-weight: 600;
}

.cautionTitle {
    text-align: center;
    text-decoration: underline;
}

.excelContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btnContainer {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 200px;
    margin-bottom: 10px;
}

.img {
    max-width: 100%;
}