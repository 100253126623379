.innerContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    align-content: space-between;
}
.inner {
    width: 90%;
    text-align: left;
    display: flex;
    /* height: 120px; */
    flex-direction: column;
    justify-content: space-around;
    align-content: space-between;
}

.pwContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.inputEye {
    display: flex;
}
.eyeContainer {
    background-color: white;
    width: 30px;
    padding: 2px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: 1px solid black;
    align-items: center;
}

.btnValidateContainer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.p {
    text-align: center;
}

.input {
    width: 135px;
}

@media screen and (max-width: 420px){
    /* .inner {
        height: 180px;
    } */
    .pwContainer {
        flex-direction: column;
        align-items: center;
    } 
}