.nav {
    background-color: white;
    font-size: 28px;
    height: 60px;
    font-weight: 600;
}

.ul {
    display: flex;
    list-style: none;
    justify-content: space-around;
    position: relative;
    flex-wrap: wrap;
    margin-top: 0;
}

.li {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    cursor:pointer;
    position: relative;
}

.li:hover {
    scale: 1.1;
    color: #F27066;
}

.subUl {
    position: absolute;
    top:60px;
    left: 10px;
    z-index: 9;
    list-style: none; 
}

.subLi {
    font-size: 22px;
    padding: 5px 10px;
    cursor:pointer;
    position: relative;
    border-bottom: 1px solid black;
    background-color: white;
}

.subLink {
    text-decoration: none;
}


.link{
    text-decoration: none;
    padding: 20px;
    min-width: 150px;
}


.icon {
    margin-left: 5px;
}
