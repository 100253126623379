.container {
    background-color: white;
    border-radius: 50px;
    padding:10px;
    cursor: pointer;
    margin-left: 20px;
}

.stopContainer {
    border-radius: 50px;
    cursor: pointer;
    margin-left: 30px;
    margin-top: 10px;
}

.icon {
    color:#28356B;
}

.icon:hover {
    color:#F27066;
}

.recordingText {
    margin-top: 5px;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.soundIcon {
    margin-top: 5px;
    margin-left: 30px;
    animation: pulse 1s infinite alternate;
    color: #F27066;
}