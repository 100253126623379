.container {
    margin-top: 40px;
    margin-bottom: 150px;
}


@keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}
  
.fadeInDown {
  animation: fadeInDown 1.5s ease-in-out;
}


.title {
  margin-top: 40px;
  margin-bottom: 55px;
}

.buttons {
    width: 90%;
    margin: auto;
    height:30px;
    margin-bottom: 30px;
}

.button {
    width: 50%;
    height:100%;
    border-color: indigo;
    font-size: large;
    cursor: pointer;
}

.title {
    text-align: center;
}