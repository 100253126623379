.container {
    width: 80%;
}
.optionsContainer {
    display: flex;
    justify-content: space-evenly;
}

.optionContainer {
    position: relative;
    padding: 15px;
    background-color: #ffffff;
    /* #6D8CBF; */
    color: #28356B;
    text-align: center;
    margin:10px 10px;
    cursor:pointer;
    width: 300px;
    border: 2px solid #28356B;
}
.optionContainer:hover {
    background-color: #28356B;
}

/* .highlight {
    background-color: #28356B;
} */

/* .highlight :hover {
    background-color: #28356B;
} */
s.type {
    text-align: center;
}

.monthPrice {
    border: 2px solid #6D8CBF;
}
/*  */
.month {
    background-color: #9BB5D8;
    padding: 5px;
}

.price {
    background-color: rgb(246, 224, 224);
    color:#28356B;
    padding: 10px 5px;
}

.equal {
    margin: 10px 0;
    color: #b41409;
}


.btnContainer {
    text-align: center;
    margin: 10px 0;
}

.link {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 700;
}

.link:hover {
    color: black;
}

.crossed {
    font-size: 11px;
    text-decoration: line-through;
}

.label {
    position: absolute;
    top:-25px;
    right: 50%; 
    transform: translateX(50%);
    background-color: #ec594e;
    color: white;
    /* #ef8f88; */
    border: 1px solid #F27066;
    padding: 10px;
    border-radius: 10px;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
}

@media screen and (max-width:820px) {
    .label {
        top:-40px;
    }
}

@media screen and (max-width:390px) {
    .optionContainer {
        margin: 10px 4px;
        padding: 10px;
    }
}