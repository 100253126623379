.nav {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: white;
    position: relative;
    position: relative;
    z-index: 1;
}

.menu {
    position: absolute;
    top:0;
    right: 0;
    background-color: white;
}


.ul {
    list-style: none;
    padding: 0 20px 20px 20px;
}
.liFirst {
    padding: 0px 10px 20px 10px;
    cursor:pointer;
    text-align: center;
    border-bottom: 1px solid #28356B;
}
.li {
    padding: 20px 10px;
    cursor:pointer;
    text-align: left;
    border-bottom: 1px solid #28356B;
}

.link{
    text-decoration: none;
    color: #28356B;
}

.close {
    text-align: left;
    padding-left: 5px;
    padding-top: 5px;
}


.menuIcon {
    position:relative;
    top:25px;
    right: 20px;
    color: #28356B;
}

.logo {
    max-width: 100px;
    margin-right: 5px;
}