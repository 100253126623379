.container {
    color: #222245;
    padding: 8px 12px;
    background-color: #fff;
    border: 1px solid #222245;
    width: 400px;
  }

  input[type=file]::file-selector-button {
    margin-right: 8px;
    border: none;
    background: #AF2B26;
    padding: 8px 12px;
    color: #fff;
    cursor: pointer;
  }