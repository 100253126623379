.container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    min-height: 400px;
}

.loaderContainer {
    height: 400px;
    position: relative;
    width: 100%;
    
}

.center {
    top:35%;
    left: 48%;
    position: absolute;
}

.box {
    background-color: #F7F7F9;
  padding: 20px 20px;
  text-align: center;
}

/* .container {
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  } */
.visibleCard {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    visibility: visible;
    transition: visibility 0.5s;
  }
  
.hiddenCard {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    visibility: hidden;
    transition: visibility 0.5s;
}

.revise {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.buttons {
    width: 220px;
    display: flex;
    justify-content: space-around;
}

.arrowIcon {
    color:#AF2B26;
    cursor: pointer;
}

.arrowIcon:hover {
    color:#93221e;
}

@keyframes fadeInDown {
    0% {
      scale: 100%;
    }
    50% {
      scale: 120%;
      color: #93221e;
    }
    100% {
        scale: 100%;
    }
}

.fadeInDown {
    animation: fadeInDown 2s ease-in-out 1.5s;
    font-weight: 750;
  }