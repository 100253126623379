.inner {
    width: 90%;
    text-align: center;
    display: flex;
    height: 120px;
    flex-direction: column;
    justify-content: space-around;
    align-content: space-between;
}

.pwContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.inputEye {
    display: flex;
}
.eyeContainer {
    background-color: white;
    width: 30px;
    padding: 2px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: 1px solid black;
    align-items: center;
}

.btnValidateContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    align-items: center;
}

.p {
    text-align: center;
}

.input {
    width: 135px;
}

.sentence {
    margin-bottom: 30px;
}



@media screen and (max-width: 420px){
    .inner {
        height: 180px;
    }
    .pwContainer {
        flex-direction: column;
        align-items: center;
    } 
}
