/* style.module.css */

.container {
    position: relative;
    background-color: white;
    border-radius: 10px;
    width: 240px;
    height: 320px;
    /* height: 150px; */
    margin: 20px 0;
    padding: 10px 0px;
    /* 10 20 */
    transition: transform 0.5s; /* Ajoutez une transition pour une animation fluide */
    cursor: pointer;
    }
.recto {
    transform: perspective(1000px) rotateY(0deg); /* Position initiale pour le recto */
}

.verso {
    transform: perspective(1000px) rotateY(180deg); /* Rotation à 180 degrés pour le verso */
}

.coloredBox {
    height: 50px;
}
.coloredBoxRecto{
    background-color:#6D8CBF;
}
.coloredBoxVerso {
    background-color:#AF2B26;
    transform: rotateY(180deg);
}
.verso .text {
    transform: rotateY(180deg); /* Rotation inverse pour le texte du verso */
    /* Ajoutez d'autres styles spécifiques au verso si nécessaire */
  }
.centeredContent {
    text-align: center;
    height: 220px; 
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
    padding: 0 20px;
}

.icon {
    width: 45px;
    position:absolute;
    left: 0;
    top: 10px;
}

.soundIcon {
    color: #28356B;
    cursor:pointer;
}

.soundIcon:hover {
    color: #F27066;
}

.icon2 {
    width: 45px;
    position:absolute;
    right: 0;
    bottom: 0;
}

.y {
    padding: 10px 25px;
    /* background-color: yellow; */
}

.flagRecto {
    width: 50px;
    position:absolute;
    right: 5px;
    top:17px;
}

.flagVerso {
    width: 50px;
    position:absolute;
    right: 5px;
    top:8px;
}

