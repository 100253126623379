.container {
    margin: 20px;
    padding: 20px;
    border: 2px solid #28356B;
    text-align: center;
    background-color: #6D8CBF;
    border-radius: 5px;
    max-width:100%;
}

.btnContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-evenly;
}

.langBtn {
    width: 100px;
}

/* .result {
    display: flex;
    width: 380px;
    margin-left: 12px;
    justify-content: space-around;
} */

.inputContainer {
    width: 95%;
    margin-bottom: 10px; /* Ajoutez ceci pour l'espacement entre les champs */
    display: flex;
    justify-content: center;
}

.copyBtn {
    cursor:pointer;
}