.container {
    margin-bottom: 10px;
    color: #28356B;
    text-align: left;
}

/* .soundIcon {
    color: black;
    cursor:pointer;
}

.soundIcon:hover {
    color: #F27066;
} */

.soundContainer {
    padding: 7px 25px;
    color: black;
    cursor:pointer;
}

.soundContainer:hover {
    color: #F27066;
}

@media screen and (max-width:600px) {
    .soundContainer {
        display: block;
        padding: 5px 25px;
        text-align: center;
        margin-bottom: -20px;
    }
}