header {
  font-family: 'Nunito', sans-serif;
}
.body {
  background-color: #9BB5D8;
  padding: 30px 20px;
  font-family: 'Lato', sans-serif;
  color: #28356B;
}

h1,h2 {
  text-align: center;
  color: #28356B;
  margin-bottom: 20px;
  font-family: 'Nunito', sans-serif;;
}

.btn {
  color:white;
  background-color: #AF2B26;
  border-radius: 15px;
  padding:5px 15px;
  border: 1px solid #AF2B26;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-size:18px;
}

.btn:hover {
  background-color: #93221e;
}

.btn-selected {
  color:white;
  background-color: #8b1a17;
  border-radius: 15px;
  padding:5px 15px;
  border: 1px solid #93221e;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-size:18px;
  font-weight: 530;
}

.descreet {
  color :#28356B;
  background-color: white;
  border: 1px solid #28356B;
}

.descreet:hover {
  background-color: white;
}

.noUnderline {
  text-decoration: none;
}

.btnSpace {
  margin: 5px 15px;
}

.container {
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 70vh;
}

.light {
  background-color: #F7F7F9;
}

.paddingTopBottom {
  padding:20px 0;
}

.maxWidth {
  max-width: 90%;
}

.box {
  background-color: #F7F7F9;
  width: 50%;
  padding: 20px 10px;
  text-align: center;
}

.option {
  color:white;
  background-color: #F27066;
  padding:10px 10px;
  border: 1px solid #F27066;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  text-align: center;
  margin: 5px 15px;
  min-width: 60px;
}

.option.selected {
  background-color: #ab4a43;
}

.optionBlue.chosen {
  background-color: #28356B;
  border: #28356B;
}


.optionBlue {
  color:white;
  background-color: #F27066;
  padding:10px 10px;
  border: 1px solid #F27066;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  text-align: center;
  margin: 5px 15px;
  min-width: 60px;
}

.optionBlue:hover{
  background-color: #28356B;
  border: #28356B;
}


.optionGreen.chosen {
  background-color: #1f7e43;
  border: #28804a;
}


.optionGreen {
  color:white;
  background-color: #F27066;
  padding:10px 10px;
  border: 1px solid #F27066;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  text-align: center;
  margin: 5px 15px;
  min-width: 60px;
}

.optionGreen:hover{
  background-color: #1f7e43;
  border: #28804a;
}



.option:hover {
  background-color: #d85d54;
}

/* DOYOUKNOW */
.red {
  background-color: #D80505;
  border: 1px solid #D80505;
}

.red:hover {
  background-color: #a90404;
}

.orange {
  background-color: #E18813;
  border: 1px solid #E18813;
}

.orange:hover {
  background-color: #905304;
}

.green {
  background-color: #358D3B;
  border: 1px solid #358D3B;
}

.green:hover {
  background-color: #1c4d1f;
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0px;
  flex-wrap: wrap;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.favicon {
  width: 45px;
}
.tdBefore {
  font-weight: 600;
}


.link {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
}

.link:hover {
  color: #F27066;
}

@media screen and (max-width: 600px) {
  .box {
    width: 100%;
  }
  .option {
    margin: 5px;
  }
}
