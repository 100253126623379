.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #9BB5D8;
    width: 90%;
}
.btnContainer {
    width: 85%;
    display: flex;
    justify-content: center;
}

.btn {
    width: 50%;
    border: 1px solid #28356B;
    padding: 5px;
    cursor: pointer;
}

.pink {
    background-color: #F27066;
    color:white;
    /* Autres styles pour la classe 'connect' */
  }
  
  .white {
    background-color: white;
    /* Autres styles pour la classe 'signup' */
  }
  

  .link {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 700;
    color: #28356B;
    margin-top:25px;
  }